// @flow

import * as React from 'react';
import appConfig from 'appConfig';
import queryString from 'qs';

import withOrguser from 'contexts/withOrguser';
import { slackRedirectDomain } from 'utils/urlUtils';

const prodClientId = 102068978614.443020162801;
const clientId = appConfig.REACT_APP_SLACK_CLIENT_ID || prodClientId;

const SigninWithSlackButton = ({ loggedInOrguser }) => {
  const redirectUri = `${slackRedirectDomain()}/oauth/slack-login`;
  const params = {
    client_id: clientId,
    redirect_uri: redirectUri,
    state: loggedInOrguser.stateCode,
  };
  const baseUrl = 'https://slack.com/oauth/v2/authorize';
  params.user_scope = 'openid,email,profile';
  const paramStr = queryString.stringify(params);
  const slackUrl = `${baseUrl}?${paramStr}`;
  return (
    <a href={slackUrl}>
      <img
        alt="Sign in with Slack"
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </a>
  );
};

export default withOrguser(SigninWithSlackButton);
