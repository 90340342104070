import development from './dev';
import production from './prod';
import staging from './staging';

const modeLookup = {
  'sales.crossbeam.com': 'production',
  'sales.cb.c7m.io': 'staging',
  'sales.crossbeam-dev.com': 'development',
};

const hostname = window?.location?.hostname;

const mode = modeLookup[hostname] || 'development';

const environmentLookup = {
  development,
  staging,
  production,
};

export default environmentLookup[mode];
