// @flow

import appConfig from 'appConfig';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { isLocalDev, isProduction } from './environment';

function objToParams(obj: any): string {
  // Turn an object into a string of url query params, with encodeURI run on values
  return Object.entries(omitBy(obj, isNil))
    .map(([key, val]) => `${key}=${encodeURIComponent(String(val))}`)
    .join('&');
}

function paramsToObj(paramsStr: string): any {
  // Inverse of objToParams. Takes string of query params and converts to an object
  // e.g. this=thing&that=otherthing returns {this: "thing", that: "otherthing"}
  const paramsStrClean = paramsStr.trim();
  return (
    paramsStrClean
      // Split string on '&' to get key=val strings
      .split('&')
      // Turn list of key=val strings to list of [key, val] lists
      .map((valStr) => valStr.split('=').map((i) => decodeURIComponent(i)))
      // Remove any where key or val
      .filter(([key, val]) => !(isNil(val) || isNil(key)))
      .reduce((prev, [key, val]) => {
        prev[key] = val;
        return prev;
      }, {})
  );
}

export function objTob64params(obj: any): string {
  return window.btoa(objToParams(obj));
}

export function b64ParamsToObj(b64params: string): any {
  return paramsToObj(window.atob(b64params));
}

export function slackRedirectDomain(): string {
  return window.location.origin;
}

export const CROSSBEAM_LOGIN_URL =
  appConfig.REACT_APP_CROSSBEAM_LOGIN_URL || 'https://app.crossbeam-dev.com/login';

export const getCrossbeamLoginUrl = (next: ?string) => {
  const nextUrl = next ? `?next=${window.location.origin}${next}` : '';
  return `${CROSSBEAM_LOGIN_URL}${nextUrl}`;
};

export const convertDevHttpURLToHttps = (url: string): string => {
  if (appConfig.ENVIRONMENT === 'development' && url.includes('http://')) {
    return url.replace('http://', 'https://');
  }
  return url;
};

export const getHubspotIntegrationUrl = () => {
  let redirectBase = 'https://sales.crossbeam.com';
  if (isLocalDev) {
    redirectBase = 'https://sales.crossbeam-dev.com';
  } else if (!isProduction) {
    // Must be staging
    redirectBase = 'https://sales.cb.c7m.io';
  }
  const scopeArr = [
    'crm.objects.contacts.read',
    'crm.objects.companies.read',
    'crm.objects.deals.read',
  ];
  const scopes = scopeArr.join(' ');
  return (
    `https://app.hubspot.com/oauth/authorize?` +
    `client_id=${appConfig.REACT_APP_HSLOGIN_CLIENT_ID}&` +
    `redirect_uri=${redirectBase}/oauth/hubspot-integration&` +
    `scope=${scopes}`
  );
};
