// @flow

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';

import { OrgUser } from 'data/entities';
import { OrgUserRoles } from 'data/entities/orguser';
import { getSignal, signalDefinitions } from 'data/entities/signal';
import { partnerRawStatuses, statusDefinitions } from 'data/entities/status';
import { fetchOrgUser } from 'data/repositories/orgusers';
import { fetchPartnerBySlug } from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';
import useOrgProvider from 'utils/useOrgProvider';
import useParams from 'utils/useParams';

import { navigatorDefaultUrl } from 'layouts/PrimaryLayout/PrimaryNavbar';
import Filter from 'components/Filter';
import Unisearchal from 'components/Unisearchal';
import UserSearch from 'components/UserSearch';

type Props = {
  loggedInOrguser: OrgUser,
};

const Sidebar = ({ loggedInOrguser: { canManage, role, capabilities } }: Props) => {
  const [
    {
      relevance,
      noPartnerActivityIn,
      status: statusRaw,
      owner: ownerRaw,
      partnerStatus: partnerStatusRaw,
      signal: signalRaw,
      partner: partnerRaw,
      hasContacts,
    },
    setParams,
  ] = useParams();
  const { isProviderCrossbeam } = useOrgProvider();

  const status = statusRaw ? [].concat(statusRaw) : [];
  const partnerStatus = partnerStatusRaw ? [].concat(partnerStatusRaw) : [];
  const signal = signalRaw ? [].concat(signalRaw) : [];

  const [owner, setOwner] = useState(null);
  const [loadingOwner, setLoadingOwner] = useState(false);
  const onOwnerSelect = (newOwner: ?OrgUser) => {
    setOwner(newOwner);
    setParams({ owner: newOwner ? newOwner.id : null }, false);
  };

  // Load initial owners.
  useEffect(() => {
    if (ownerRaw && ownerRaw.length) {
      setLoadingOwner(true);
      fetchOrgUser(ownerRaw)
        .then((newOwner: OrgUser) => {
          setLoadingOwner(false);
          setOwner(newOwner);
        })
        .catch(() => setLoadingOwner(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On query clear, clear owners.
  useEffect(() => {
    if (isNil(ownerRaw)) {
      setOwner(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerRaw]);

  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const partner = partnerRaw ? [].concat(partnerRaw) : [];
  const partnerDependency = JSON.stringify(partner);
  const onPartnerSelect = (newPartners: ?(SearchIndexItem[])) => {
    setPartners(newPartners);
    setParams({ partner: newPartners.map((p) => p.slug) }, false);
  };

  // Load initial partners.
  useEffect(() => {
    if (!capabilities.canAccessSeatedExperience) {
      return;
    }
    setPartners([]);
    const promises = [];
    partner.forEach((slug) => promises.push(fetchPartnerBySlug(slug)));
    setLoadingPartners(true);
    Promise.all(promises)
      .then((newPartners: Partner[]) => {
        setLoadingPartners(false);
        setPartners(
          newPartners.map((partnerObj: Partner): SearchIndexItem => ({
            id: partnerObj.id,
            kind: 'partner',
            name: partnerObj.name,
            slug: partnerObj.slug,
          }))
        );
      })
      .catch(() => setLoadingPartners(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilities.canAccessSeatedExperience]);

  // On query clear, clear partners.
  useEffect(() => {
    if (partner.length === 0) {
      setPartners([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerDependency]);

  return (
    <div className="col-3 page-top-margin">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="d-flex flex-column gap-15">
          <Link to="/lists" className="mb-2 btn btn-secondary btn-block gray-700">
            <FontAwesomeIcon icon="list" className="mr-2" />
            View Saved Lists
          </Link>
          <div
            className={`mb-1 d-flex flex-row gap-10 ${
              !capabilities.canAccessSeatedExperience ? 'seatless-overlay' : ''
            }`}
          >
            <span className="bold large-text gray-700 flex-fill">Filters</span>
            <Link to={navigatorDefaultUrl()}>Reset</Link>
          </div>
          <div
            className={`d-flex flex-column stacked-cards ${
              !capabilities.canAccessSeatedExperience ? 'seatless-overlay' : ''
            }`}
          >
            {!isProviderCrossbeam && (
              <Filter title="Partner Deal Signals">
                {Object.keys(signalDefinitions).map((key) => {
                  const {
                    key: signalKey,
                    label,
                    colorRaw,
                    icon,
                    contactExclusive,
                  } = getSignal(key);
                  const checked = signal.includes(signalKey);
                  return (
                    !contactExclusive && (
                      <button
                        onClick={() =>
                          setParams(
                            {
                              signal: checked
                                ? signal.filter((v) => v !== signalKey)
                                : signal.concat(signalKey),
                            },
                            false
                          )
                        }
                        key={signalKey}
                        className="btn btn-link p-0 d-flex flex-row align-items-center gap-10"
                        style={{ cursor: 'pointer' }}
                        type="button"
                      >
                        <input type="checkbox" checked={checked} readOnly />
                        <FontAwesomeIcon
                          style={{ flex: '0 0 20px' }}
                          color={colorRaw}
                          icon={icon}
                        />
                        <span className="normal-text bold gray-700">{label}</span>
                      </button>
                    )
                  );
                })}
              </Filter>
            )}
            <Filter title="My Accounts">
              <Filter isPrimary={false} title="Account Status">
                {Object.keys(statusDefinitions).map((key) => {
                  const { key: statusKey, label, colorRaw, icon } = statusDefinitions[key];
                  const checked = status.includes(statusKey);
                  return (
                    <button
                      onClick={() =>
                        setParams(
                          {
                            status: checked
                              ? status.filter((v) => v !== statusKey)
                              : status.concat(statusKey),
                          },
                          false
                        )
                      }
                      key={statusKey}
                      className="btn btn-link p-0 d-flex flex-row align-items-center gap-10"
                      style={{ cursor: 'pointer' }}
                      type="button"
                    >
                      <input type="checkbox" checked={checked} readOnly />
                      <FontAwesomeIcon style={{ flex: '0 0 20px' }} color={colorRaw} icon={icon} />
                      <span className="normal-text bold gray-700">{label}</span>
                    </button>
                  );
                })}
              </Filter>
              {/*  <Filter isPrimary={false} title="Target Accounts">
                <AccountIsTargetField
                  showAll={isTarget}
                  selected={isTargetValue}
                  onChange={(selected: ?string) => {
                    const newFilters = {
                      isTargetValue: selected !== ALL_TARGET_ACCOUNTS ? selected : null,
                      isTarget: selected === ALL_TARGET_ACCOUNTS ? true : null,
                    };
                    setParams(newFilters, false);
                  }}
                />
              </Filter> */}
              <Filter title="Show Accounts For" isPrimary={false}>
                <select
                  value={isNil(relevance) ? 'all' : relevance}
                  className="full-width form-control uppercase form-control-md"
                  onChange={(e) => {
                    const { value } = e.target;
                    setParams({ relevance: value }, false);
                  }}
                >
                  {role === OrgUserRoles.BD && <option value="partner_manager">My Partners</option>}
                  <option value="sales_person">My Accounts</option>
                  <option value="all">All Accounts</option>
                </select>
              </Filter>
              <Filter isPrimary={false} title="Owner">
                <UserSearch
                  onChange={onOwnerSelect}
                  placeholder="🔎  Search for account owner..."
                  selected={owner ? [owner] : null}
                  changeOnClear
                  isLoading={loadingOwner}
                  disabled={loadingOwner}
                  excludeCrmUsersWithAuth
                />
              </Filter>
              {/*  <Filter isPrimary={false} title="Segments">
                <SegmentSelector
                  segment={segment}
                  onChange={(result: ?(Option[])) =>
                    setParams(
                      {
                        segment: result.map((x) => x.value),
                      },
                      false
                    )
                  }
                />
              </Filter> */}
            </Filter>
            {!isProviderCrossbeam && (
              <Filter title="Contacts">
                <button
                  onClick={() =>
                    setParams(
                      {
                        hasContacts: hasContacts ? null : true,
                      },
                      false
                    )
                  }
                  className="btn btn-link p-0 d-flex flex-row align-items-center gap-10"
                  style={{ cursor: 'pointer' }}
                  type="button"
                >
                  <input type="checkbox" checked={hasContacts === 'true'} readOnly />
                  <span className="normal-text bold gray-700">Only accounts with contacts</span>
                </button>
              </Filter>
            )}
            <Filter title="Partners">
              <Filter isPrimary={false} title="Partner Account Status">
                {Object.keys(statusDefinitions)
                  .filter((key) => partnerRawStatuses.includes(key))
                  .map((key) => {
                    const { key: statusKey, label, colorRaw, icon } = statusDefinitions[key];
                    const checked = partnerStatus.includes(statusKey);
                    return (
                      <button
                        onClick={() =>
                          setParams(
                            {
                              partnerStatus: checked
                                ? partnerStatus.filter((v) => v !== statusKey)
                                : partnerStatus.concat(statusKey),
                            },
                            false
                          )
                        }
                        key={statusKey}
                        className="btn btn-link p-0 d-flex flex-row align-items-center gap-10"
                        style={{ cursor: 'pointer' }}
                        type="button"
                      >
                        <input type="checkbox" checked={checked} readOnly />
                        <FontAwesomeIcon
                          style={{ flex: '0 0 20px' }}
                          color={colorRaw}
                          icon={icon}
                        />
                        <span className="normal-text bold gray-700">{label}</span>
                      </button>
                    );
                  })}
              </Filter>
              <Filter isPrimary={false} title="Partners">
                <Unisearchal
                  placeholder="🔎  Search for partners..."
                  entityKind="partner"
                  selected={partners}
                  isLoading={loadingPartners}
                  onChange={onPartnerSelect}
                  onClear={onPartnerSelect}
                  stripUnisearchalClassName
                  loadInitial
                  multiple
                />
              </Filter>
            </Filter>
            <Filter title="No Crossbeam for Sales Activity In">
              <select
                value={isNil(noPartnerActivityIn) ? 'none' : +noPartnerActivityIn}
                className="full-width form-control uppercase form-control-md"
                onChange={(e) => {
                  const { value } = e.target;
                  setParams({ noPartnerActivityIn: value === 'none' ? null : value }, false);
                }}
              >
                <option value="none">(none)</option>
                {[3, 6, 12].map((count) => (
                  <option key={count} value={count}>{`${count} months`}</option>
                ))}
              </select>
            </Filter>
          </div>
        </div>
      </StickyBox>
    </div>
  );
};

export default withOrguser(Sidebar);
