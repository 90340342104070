// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appConfig from 'appConfig';

import { useTitleEffect } from 'utils/setTitle';

import Logo from 'components/Logo';

import Footer from './Footer';

const NoAccess = ({ onClick }) => {
  useTitleEffect('No Access');

  return (
    <div className="d-flex flex-column disable-selection my-4" style={{ gap: 120 }}>
      <Logo height={25} />
      <div className="d-flex flex-column align-items-center disable-selection gap-10">
        <FontAwesomeIcon icon="eye-slash" className="gray-400" size="2x" />
        <h3 className="bold gray-700 m-0">You don&apos;t have access to Crossbeam for Sales</h3>
        <span className="large-text semi-bold gray-600">Please talk to your Crossbeam admin.</span>
        <a href={appConfig.REACT_APP_CROSSBEAM_URL}>
          <button className="btn btn-primary btn-large mt-4" type="button">
            Go back to Crossbeam
          </button>
        </a>
        <button onClick={onClick} className="btn btn-ghost btn-medium mt-4" type="button">
          Change Organization
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default NoAccess;
