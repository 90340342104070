const config = {
  REACT_APP_ENVIRONMENT: 'cross-staging',
  REACT_APP_BACKEND_API_URL: 'https://sales-backend-api.cb.c7m.io',

  REACT_APP_SALESFORCE_LOGIN_URL:
    'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9oNqAtcJCF.Eh3eZ1thTO2Hm5zTbqBLJNdGKKXz17H4J1weNzCIQsj9Q_rbQIdOusoeANZLWeaa04fcvn&redirect_uri=https://sales.cb.c7m.io/oauth/salesforce-login',
  REACT_APP_SALESFORCE_DATA_AUTH_URL:
    'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9oNqAtcJCF.Eh3eZ1thTO2NwE5vQ_6uI7jOaQEoUOHlZLxk5H1ONSkARUK4eD8Rv7FxWp8g==&redirect_uri=https://sales.cb.c7m.io/oauth/salesforce-data',

  REACT_APP_CROSSBEAM_DATA_AUTH_URL:
    'https://auth.cb.c7m.io/authorize?audience=https://api.cb.c7m.io&response_type=code&client_id=8j9R98rvHOBC0iI8Znj0OGckUmgw1HpA&redirect_uri=https%3A%2F%2Fsales.cb.c7m.io%2Foauth%2Fcrossbeam-data&scope=openid%20read%3Areports%20read%3Apartnerships%20offline_access',

  REACT_APP_HUBSPOT_LOGIN_URL:
    'https://app.hubspot.com/oauth/authorize?client_id=dfbafde5-9be0-4ce4-bb15-c1b76d497ed5&redirect_uri=https://sales.cb.c7m.io/oauth/hubspot-login&scope=oauth',
  REACT_APP_CROSSBEAM_LOGIN_URL: 'https://app.cb.c7m.io/login',
  REACT_APP_SLACK_CLIENT_ID: '1712934082615.6051826944499',

  REACT_APP_HSLOGIN_CLIENT_ID: '569cb9ed-a9c9-4c0c-b019-c02719bbed04',

  REACT_APP_MAGICBELL_KEY: '3227a0e37101718943e99fce32492e2bb8e16e3d',

  REACT_APP_RECAPTCHA_SITE_KEY: '6LeZtyQbAAAAAO3JJKB0CGlXs-jgsVGgB_WR7qqi',

  REACT_APP_HOTGLUE_API_KEY: '9XSms2ulJ55dB6pq4OCnm6HklTW4Z3rq9HHlKPtU',
  REACT_APP_HOTGLUE_ENV_ID: 'cross-staging.hotglue.partnered.io',
  REACT_APP_HOTGLUE_FLOW_ID: 'ulFC5LfQ3',

  REACT_APP_CROSSBEAM_SWITCH_ORG_URL: 'https://app.cb.c7m.io/home',
  REACT_APP_CROSSBEAM_URL: 'https://app.cb.c7m.io',
  REACT_APP_CROSSBEAM_API_URL: 'https://api.cb.c7m.io',

  REACT_APP_HUBSPOT_PORTALID: '5767578',
  REACT_APP_HUBSPOT_FORMID_UPGRADE: '846e4458-a3ea-42a4-ae97-f148221fd56b',
  REACT_APP_CROSSBEAM_STATUS_URL: 'https://crossbeamstaging.statuspage.io',
  ENVIRONMENT: 'production',
};

export default config;
