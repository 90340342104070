import appConfig from 'appConfig';
import moment from 'moment';

import cacheBusterMeta from '../meta';

const cacheBuster = () => {
  // Check if there is a new version available.
  if (appConfig.ENVIRONMENT !== 'development') {
    fetch('/meta.json', {
      headers: {
        'Cache-Control': 'no-store',
      },
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestHash = meta.hash;
        const currentHash = cacheBusterMeta.hash;
        const lastCheck = localStorage.getItem('cachebuster');
        // If hash different and it has never been checked or if it has been checked more than 15 minutes ago, reload.
        if (
          (lastCheck === null ||
            Math.abs(moment(lastCheck).diff(moment(new Date()), 'minutes')) > 15) &&
          latestHash !== currentHash
        ) {
          // eslint-disable-next-line no-console
          console.log(
            `%cNew version available - force refreshing. Last check: ${
              lastCheck === null ? 'never' : moment(lastCheck).toISOString()
            }`,
            'color: #042C59'
          );
          caches.keys().then((names) => {
            for (const name of names) caches.delete(name);
          });
          window.location.reload(true);
          // Set time of reload.
          localStorage.setItem('cachebuster', new Date().toISOString());
        }
      })
      .catch(() => {
        // Something failed, don't get stuck in a loop.
        localStorage.setItem('cachebuster', new Date().toISOString());
      });
  }
};

export default cacheBuster;
