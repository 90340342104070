// @flow
import appConfig from 'appConfig';
import queryString from 'qs';

import captureDatadogError from 'utils/captureDatadogError';

import { getXBOrg, parseJSON } from './utils';

const API_URL = appConfig.REACT_APP_CROSSBEAM_API_URL || 'http://localhost:8000';

export function buildUrl(endpoint: string, queryParams: ?{ [key: string]: string } = {}): string {
  // Create a query string to add to the url
  const strQueryParams = queryString.stringify(queryParams);
  const queryStr: string = strQueryParams ? `?${strQueryParams}` : '';
  const builtEndpoint = `${endpoint}${queryStr}`;
  // If the url starts with http, then don't add the API_URL
  return endpoint.slice(0, 4) === 'http' ? builtEndpoint : `${API_URL}/${builtEndpoint}`;
}

type InitOptions = {
  method?: string,
  body?: {} | any[],
  queryParams?: ?{},
  headers?: {
    'Content-Type'?: string,
    'XBeam-Organization': string,
  },
};

export default function authFetchXB(
  endpoint: string,
  init: InitOptions = {},
  rawResponse: any = false
): Promise<any> {
  // Provides same interface as .fetch, PLUS the `queryParams` key in the init
  // but handles adding the authorization header under the hood.
  const { queryParams, headers: givenHeaders = {}, body: givenBody, ...restArgs } = init;
  const contentType = givenHeaders['Content-Type'];

  let body;
  if (
    givenBody &&
    !(givenBody instanceof FormData) &&
    (!contentType || contentType === 'application/json')
  ) {
    givenHeaders['Content-Type'] = 'application/json';
    body = JSON.stringify(givenBody);
  } else {
    body = givenBody;
  }

  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    ...restArgs,
    body,
    headers: {
      'XBeam-Organization': getXBOrg(),
      ...givenHeaders,
    },
  };

  const url = buildUrl(endpoint, queryParams);
  if (rawResponse) {
    return fetch(url, fetchInit);
  }
  return fetch(url, fetchInit)
    .then(parseJSON)
    .catch((error) => {
      // Capture the error in Datadog and return the error to the caller
      captureDatadogError(error);
      return Promise.reject(error);
    });
}

export type { InitOptions };
