// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, get, isEmpty } from 'lodash';
import queryString from 'qs';

import { OrgUser, Partner } from 'data/entities';
import { AccountDetailAccountPartner } from 'data/repositories/accounts';
import { fetchOrgUser } from 'data/repositories/orgusers';
import type { AccountPartnerQueryParams } from 'data/repositories/partners';
import {
  fetchPartnerAccountPartners,
  PartnerDetailAccountPartner,
} from 'data/repositories/partners';
import withOrguser from 'contexts/withOrguser';
import type { PaginatedResponse } from 'sharedTypes/paginatedResponse';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import BasePaginationButtons from 'components/PaginationButtons';
import UserSearch from 'components/UserSearch';

import AccountMappingFilters from './AccountMappingFilters';
import AccountMappingSorting from './AccountMappingSorting';
import PartnerRow, { requestBtnWidth } from './PartnerRow';

type Props = {
  accountPartners: PartnerDetailAccountPartner[],
  partner: Partner,
  isLoading: boolean,
  showStartMapping: boolean,
};

function PartnerAccounts(props: Props) {
  const { accountPartners, partner, isLoading, showStartMapping, url, history, query } = props;
  const { partnerOrg, name, mappingUrl } = partner;

  switch (true) {
    case isLoading:
      return <LoadingRing maxWidth="50px" text="Loading mapped accounts..." />;
    case showStartMapping && partner.settings.isManualMapping:
      return (
        <p className="text-center">
          <strong>Use &quot;</strong>
          <FontAwesomeIcon icon="cog" />
          Sharing Settings<strong>&quot; above to share all overlapping accounts</strong>
          <br />
          <br />
          Or click to <Link to={mappingUrl}>map individual accounts manually</Link> for now.
        </p>
      );
    case showStartMapping &&
      !partner.settings.isManualMapping &&
      partner.settingsByPartner.isManualMapping:
      return <p className="text-center">Waiting on {name} to share accounts</p>;
    case showStartMapping:
      return (
        <div className="text-center mt-4 large-text bold">
          Looking for overlapping accounts. This can take a while, check back later!
        </div>
      );
    default:
      return (
        <div className="PartnerAccounts">
          <div className="d-flex px-2">
            <div className="mr-3" style={{ width: requestBtnWidth }}>
              {' '}
            </div>
            <div className="flex-1 pt-3 ">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6>Your Organization</h6>
                <AccountMappingFilters
                  query={query}
                  url={url}
                  history={history}
                  own
                  partner={partner}
                />
              </div>
            </div>
            <div className="flex-1 pt-3 AccountInformation__highlighted">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="my-1">
                  <CompanyLogo
                    name={partnerOrg.name}
                    logoUrl={partnerOrg.logoUrl}
                    domain={partnerOrg.domain}
                    size={20}
                    className="mr-1"
                  />{' '}
                  {partnerOrg.name}
                </h6>
                <AccountMappingFilters
                  query={query}
                  url={url}
                  history={history}
                  partner={partner}
                />
              </div>
            </div>
          </div>
          {accountPartners && accountPartners.length ? (
            accountPartners.map((p) => (
              <PartnerRow key={p.id} accountPartner={p} partner={partner} />
            ))
          ) : (
            <div className="text-center my-3">
              <h5>No Results</h5>
            </div>
          )}
        </div>
      );
  }
}

const OWNER = 'accountOwner';
const ALL_OWNERS = { id: 'all', fullName: 'All account owners' };

type ContainerProps = {
  loggedInOrguser: OrgUser,
  partner: Partner,
  query: AccountPartnerQueryParams,
  url: string,
  history: RouterHistoryT,
};

type State = {
  isLoading: boolean,
  accountPartnerPage: PaginatedResponse<AccountDetailAccountPartner>,
  isLoadingPagination: boolean,
  loadingAccountOwner: boolean,
  accountOwner: OrgUser[],
};

class PartnerAccountsContainer extends React.Component<ContainerProps, State> {
  state: State = {
    isLoading: true,
    accountPartnerPage: null,
    isLoadingPagination: true,
    loadingAccountOwner: false,
    accountOwner: [ALL_OWNERS],
  };

  componentDidMount() {
    this.debouncedUpdate = debounce(this.loadAccountPartners, 300);
    const changed = this.checkIfPartnerFilterPermissions();
    if (!changed) {
      this.loadAccountPartners();
    }

    // Load account owner filter.
    const query = get(this.props, 'query');
    if (query && query.accountOwner) {
      this.loadAccountOwner(query.accountOwner === 'all' ? '' : query.accountOwner);
    }
  }

  componentDidUpdate(prevProps: ContainerProps) {
    if (
      get(prevProps, 'partner.slug') !== get(this.props, 'partner.slug') ||
      get(prevProps, 'query') !== get(this.props, 'query')
    ) {
      const { accountPartnerPage } = this.state;
      const hasAccounts =
        accountPartnerPage && accountPartnerPage.results && accountPartnerPage.results.length;
      const nextState = !hasAccounts
        ? { isLoading: true, isLoadingPagination: true }
        : { isLoadingPagination: true };
      this.setState(nextState, () => this.debouncedUpdate());
    }
  }

  onOwnerChange = (result?: OrgUser) => {
    const { history, query, url } = this.props;
    const queryStr = queryString.stringify(
      { ...query, [OWNER]: result?.id || 'all' },
      { skipNulls: true, encode: false, arrayFormat: 'brackets' }
    );
    const goto = queryStr ? `${url}?${queryStr}` : url;
    history.push(goto);
    this.setState({ accountOwner: result ? [result] : [] });
  };

  loadAccountOwner = (ownerId: string) => {
    const { partner, own } = this.props;
    const { canFilterPartnerAccountStatus } = partner;
    if (!ownerId || (!own && !canFilterPartnerAccountStatus)) {
      return;
    }
    this.setState({ loadingAccountOwner: true }, () => {
      fetchOrgUser(ownerId)
        .then((owner) => this.setState({ [OWNER]: [owner], loadingAccountOwner: false }))
        .catch(() => this.setState({ loadingAccountOwner: false }));
    });
  };

  debouncedUpdate: (ContainerProps) => void = () => {};

  checkIfPartnerFilterPermissions = (): boolean => {
    const { partner, query, url, history } = this.props;
    let changeQuery = false;
    const currentFilters = {};
    const { canFilterPartnerAccountStatus, canFilterPartnerAccountOwner } = partner;
    if (query.partnerStatus && !canFilterPartnerAccountStatus) {
      currentFilters.partnerStatus = null;
      changeQuery = true;
    }
    if (query.partnerAccountOwner && !canFilterPartnerAccountOwner) {
      currentFilters.partnerAccountOwner = null;
      changeQuery = true;
    }
    if (changeQuery) {
      const queryStr = queryString.stringify(
        { ...query, ...currentFilters },
        { skipNulls: true, encode: false, arrayFormat: 'brackets' }
      );
      const goto = queryStr ? `${url}?${queryStr}` : url;
      history.replace(goto);
    }
    return changeQuery;
  };

  async loadAccountPartners(pageUrl: string = null) {
    const { partner, query } = this.props;
    const partnerSlug = partner && partner.slug;
    if (!partnerSlug) {
      return;
    }

    try {
      const accountPartnerPage = await fetchPartnerAccountPartners(partnerSlug, 20, pageUrl, query);
      this.setState({
        isLoading: false,
        isLoadingPagination: false,
        accountPartnerPage,
      });
    } catch (error) {
      this.setState({ isLoading: false, isLoadingPagination: false });
    }
  }

  render() {
    const { loggedInOrguser, partner, query, url, history } = this.props;
    const {
      accountPartnerPage,
      isLoading,
      isLoadingPagination,
      loadingAccountOwner,
      accountOwner,
    } = this.state;

    const onNext =
      accountPartnerPage && accountPartnerPage.next
        ? () =>
            this.setState({ isLoadingPagination: true }, () =>
              this.loadAccountPartners(accountPartnerPage.next)
            )
        : null;
    const onPrev =
      accountPartnerPage && accountPartnerPage.previous
        ? () =>
            this.setState({ isLoadingPagination: true }, () =>
              this.loadAccountPartners(accountPartnerPage.previous)
            )
        : null;

    const accounts = accountPartnerPage && accountPartnerPage.results;

    const showOwnerFilter = partner.settingsByPartner?.visibilitySettings?.accountOwnerVisible;

    return (
      <>
        <div className="d-flex align-items-center gap-10 my-2">
          <h5 className="m-0 bold gray-700">Overlapping accounts for:</h5>
          <div className="d-flex flex-fill align-items-center">
            {showOwnerFilter && (
              <>
                {loadingAccountOwner ? (
                  <input
                    type="text"
                    className="form-control form-control-sm input-sm mr-2"
                    style={{ maxWidth: '145px' }}
                    placeholder="Loading..."
                    disabled
                  />
                ) : (
                  <UserSearch
                    defaultOptions={[ALL_OWNERS]}
                    placeholder="🔎  Search for an account owner"
                    selected={accountOwner}
                    onChange={this.onOwnerChange}
                    style={{ width: 250 }}
                    changeOnClear
                    excludeCrmUsersWithAuth
                  />
                )}
              </>
            )}
          </div>
          <AccountMappingSorting query={query} url={url} history={history} />
        </div>
        <div className="card pb-3">
          <div className="card-body">
            {partner.isSynced ? (
              <>
                <PartnerAccounts
                  accountPartners={accounts}
                  partner={partner}
                  onNext={onNext}
                  onPrev={onPrev}
                  isLoading={isLoading}
                  showStartMapping={!(accounts && accounts.length) && isEmpty(query)}
                  url={url}
                  history={history}
                  query={query}
                />
                <BasePaginationButtons
                  onNext={onNext}
                  onPrevious={onPrev}
                  className="pull-right mt-2"
                  loading={isLoadingPagination}
                />
              </>
            ) : (
              <div className="d-flex flex-column m-3 align-items-center">
                <span className="large-text bold gray-700">
                  This partner&apos;s data is not enabled in Crossbeam for Sales.
                </span>
                <span className="normal-text semi-bold gray-600">
                  Your team will not see data for this partner.
                </span>
                {loggedInOrguser.canManage && (
                  <Link
                    to={`/crossbeam?partner=${partner.id}&action=enable`}
                    className="btn btn-primary mt-3"
                  >
                    Enable data
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withOrguser(PartnerAccountsContainer);
