// @flow

import appConfig from 'appConfig';

import {
  CrossbeamIntegration,
  CrossbeamOrg,
  CrossbeamPartner,
  CrossbeamPopulation,
  CrossbeamSource,
  CrossbeamStatusComponents,
} from 'data/entities/crossbeam';
import { CROSSBEAM } from 'data/entities/integrationProviders';
import authFetch from 'authFetch/authFetch';
import authFetchXB from 'authFetch/authFetchXB';
import { orgUrlBase, parseJSON } from 'authFetch/utils';

const fetchCrossbeamIntegration = (): Promise<CrossbeamIntegration> => {
  const url = `${orgUrlBase()}/crossbeam-integration`;
  return authFetch(url).then(CrossbeamIntegration.fromApi);
};

const createCrossbeamIntegration = (code: string): Promise<CrossbeamIntegration> => {
  const url = `${orgUrlBase()}/crossbeam-integration/`;
  const body = { provider: CROSSBEAM, code };
  const options = { method: 'POST', body };
  return authFetch(url, options).then(CrossbeamIntegration.fromApi);
};

const fetchCrossbeamOrgs = (): Promise<CrossbeamOrg[]> => {
  const url = `${orgUrlBase()}/crossbeam-integration/organizations`;
  return authFetch(url).then((orgs) => orgs && orgs.map(CrossbeamOrg.fromApi));
};

type StandardPopulations = {
  actual: CrossbeamPopulation[],
  expected: { name: string, status: string }[],
  complete: boolean,
};

const fetchStandardCrossbeamPopulations = (): Promise<StandardPopulations> => {
  const url = `${orgUrlBase()}/crossbeam-integration/standard-populations/`;
  return authFetch(url).then((data) => ({
    ...data,
    actual: data.actual && data.actual.map(CrossbeamPopulation.fromApi),
  }));
};

const setCrossbeamOrg = (orgId: string): Promise<CrossbeamIntegration> => {
  const url = `${orgUrlBase()}/crossbeam-integration/set-organization/`;
  const body = { org_id: orgId };
  const options = { method: 'POST', body };
  return authFetch(url, options).then(CrossbeamIntegration.fromApi);
};

const fetchCrossbeamPartners = (): Promise<CrossbeamPartner[]> => {
  const url = `${orgUrlBase()}/crossbeam-integration/partners/`;
  return authFetch(url).then((partners) => partners && partners.map(CrossbeamPartner.fromApi));
};

type SyncPartner = {
  id: string,
  populations: {
    id: string,
    status: string,
  }[],
};

const setCrossbeamPartners = (partners: SyncPartner[]): Promise<CrossbeamPartner[]> => {
  const url = `${orgUrlBase()}/crossbeam-integration/partners/`;
  const options = { method: 'POST', body: partners };
  return authFetch(url, options).then(CrossbeamPartner.fromApi);
};

const hideCrossbeamPartner = (partnerId: string): Promise<CrossbeamPartner[]> =>
  fetchCrossbeamPartners().then((partners) => {
    // Filter out the partner we want to hide
    const filteredPartners = partners.filter(
      (cbp) => cbp.imported && cbp.partner?.id !== partnerId
    );
    return setCrossbeamPartners(
      filteredPartners.map((partner) => ({
        id: partner.id,
        populations: partner.populations.map(({ id, status }) => ({
          id,
          status: status || 'UNKNOWN',
        })),
      }))
    );
  });

const getCrossbeamStatus = (): Promise<CrossbeamStatusComponents> => {
  const url = `${appConfig.REACT_APP_CROSSBEAM_STATUS_URL}/api/v2/components.json`;
  return fetch(url).then(parseJSON).then(CrossbeamStatusComponents.fromApi);
};

const getCrossbeamSources = (): Promise<{ items: CrossbeamSource[] }> => {
  const url = 'v0.1/sources';
  return authFetchXB(url).then(CrossbeamSource.fromApi);
};

export {
  createCrossbeamIntegration,
  fetchCrossbeamIntegration,
  fetchCrossbeamOrgs,
  fetchCrossbeamPartners,
  fetchStandardCrossbeamPopulations,
  getCrossbeamSources,
  getCrossbeamStatus,
  hideCrossbeamPartner,
  setCrossbeamOrg,
  setCrossbeamPartners,
};
