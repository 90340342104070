// @flow

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import appConfig from 'appConfig';

import authFetch from '../../authFetch/authFetch';
import { ErrorResponse } from '../../authFetch/entities';

const RECAPTCHA_SITE_KEY = appConfig.REACT_APP_RECAPTCHA_SITE_KEY;

export default class ForgetPasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      ErrorMessage: {},
      success_message: '',
      captcha: null,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.recaptchaRef.current.execute();
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onCaptchaChange = (CaptchaValue) => {
    if (CaptchaValue === null) {
      this.recaptchaRef.current.execute();
      return;
    }
    this.setState({
      captcha: CaptchaValue,
    });
  };

  OnsendResetPasswordRequest = (e) => {
    e.preventDefault();
    this.setState({
      ErrorMessage: {},
      success_message: '',
    });

    if (!this.isValidEmail()) {
      this.setState({
        ErrorMessage: {
          email: 'This is not a valid email',
        },
      });
      return;
    }
    const body = {
      email: this.state.email,
      captcha: this.state.captcha,
    };
    authFetch('auth/recover/password/request/', { method: 'POST', body })
      .then(() => {
        this.setState({
          success_message: 'Reset password link has been sent to your email',
        });
        return {};
      })
      .catch(({ json }: ErrorResponse) => {
        let ErrorMessage = json;
        if (json.captcha) {
          ErrorMessage = json.captcha;
        }
        this.setState({
          ErrorMessage,
          captcha: null,
        });
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
      });
  };

  isValidEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  }

  render() {
    const { captcha } = this.state;
    const disableSubmitButton = captcha === null;
    const tooltipId = 'hidden-tooltip';
    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-3 text-left">
          <div className="card">
            <div className="card-body">
              <h4 className="text-center mt-1 mb-4">Reset your password</h4>

              {this.state.success_message ? (
                <div className="alert alert-success font-weight-bolder" role="alert">
                  {this.state.success_message}
                </div>
              ) : (
                ''
              )}
              {this.state.ErrorMessage[0] ? (
                <div className="alert alert-danger font-weight-bolder" role="alert">
                  {this.state.ErrorMessage[0]}
                </div>
              ) : (
                ''
              )}
              <span>
                Enter the email address associated with your account and we&apos;ll send you a link
                to reset your password.
              </span>
              <form onSubmit={this.OnsendResetPasswordRequest}>
                <div className="form-group mt-4">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    className={`form-control ${this.state.ErrorMessage.email ? 'is-invalid' : ''}`}
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    onChange={this.onInputChange}
                    placeholder="Enter your email"
                  />
                  {this.state.ErrorMessage.email ? (
                    <div id="validationServer03Feedback" className="invalid-feedback">
                      {this.state.ErrorMessage.email}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      ref={this.recaptchaRef}
                      size="invisible"
                      onChange={this.onCaptchaChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <Link to="/login" className="alert-link align-middle">
                        <span className="font-weight-bold ">Back to login</span>
                      </Link>
                    </div>
                    <div className="col-md-6 text-right">
                      {disableSubmitButton && (
                        <UncontrolledTooltip placement="top" target={tooltipId}>
                          Loading captcha...
                        </UncontrolledTooltip>
                      )}
                      <span id={tooltipId}>
                        <button
                          disabled={disableSubmitButton}
                          style={{ pointerEvents: disableSubmitButton ? 'none' : '' }}
                          type="submit"
                          className="btn btn-primary btn-lg text-bold text-center font-weight-bold"
                        >
                          Send
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
