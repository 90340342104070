const config = {
  REACT_APP_ENVIRONMENT: 'sales-prod',
  REACT_APP_BACKEND_API_URL: 'https://sales-backend-api.crossbeam.com',

  REACT_APP_SALESFORCE_LOGIN_URL:
    'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9oNqAtcJCF.Eh3eZ1thTO2Hm5zTbqBLJNdGKKXz17H4J1weNzCIQsj9Q_rbQIdOusoeANZLWeaa04fcvn&redirect_uri=https://sales.crossbeam.com/oauth/salesforce-login',
  REACT_APP_SALESFORCE_DATA_AUTH_URL:
    'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9oNqAtcJCF.Eh3eZ1thTO2NwE5vQ_6uI7jOaQEoUOHlZLxk5H1ONSkARUK4eD8Rv7FxWp8g==&redirect_uri=https://sales.crossbeam.com/oauth/salesforce-data',

  REACT_APP_CROSSBEAM_DATA_AUTH_URL:
    'https://auth.crossbeam.com/authorize?audience=https://api.getcrossbeam.com&response_type=code&client_id=xAzSMFaa6D9F88iBHqQ4cPy2aISFFIcv&redirect_uri=https%3A%2F%2Fsales.crossbeam.com%2Foauth%2Fcrossbeam-data&scope=openid%20read%3Areports%20read%3Apartnerships%20offline_access',

  REACT_APP_HUBSPOT_LOGIN_URL:
    'https://app.hubspot.com/oauth/authorize?client_id=dfbafde5-9be0-4ce4-bb15-c1b76d497ed5&redirect_uri=https://sales.crossbeam.com/oauth/hubspot-login&scope=oauth',
  REACT_APP_CROSSBEAM_LOGIN_URL: 'https://app.crossbeam.com/login',
  REACT_APP_SLACK_CLIENT_ID: '361786133558.3916419571749',

  REACT_APP_HSLOGIN_CLIENT_ID: '7cf7680f-e1c7-40d2-a455-7cf9fc331628',

  REACT_APP_MAGICBELL_KEY: '8f4584c99ce94affa975f84e3ca6e840d30aae90',

  REACT_APP_RECAPTCHA_SITE_KEY: '6LeZtyQbAAAAAO3JJKB0CGlXs-jgsVGgB_WR7qqi',

  REACT_APP_HOTGLUE_API_KEY: '9XSms2ulJ55dB6pq4OCnm6HklTW4Z3rq9HHlKPtU',
  REACT_APP_HOTGLUE_ENV_ID: 'staging.hotglue.partnered.io',
  REACT_APP_HOTGLUE_FLOW_ID: 'ulFC5LfQ3',

  REACT_APP_CROSSBEAM_SWITCH_ORG_URL: 'https://app.crossbeam.com/home',
  REACT_APP_CROSSBEAM_URL: 'https://app.crossbeam.com',
  REACT_APP_CROSSBEAM_API_URL: 'https://api.crossbeam.com',

  REACT_APP_HUBSPOT_PORTALID: '4716094',
  REACT_APP_HUBSPOT_FORMID_UPGRADE: '28accbe8-9751-4799-aef3-db69d5854f10',

  REACT_APP_FULLSTORY_ORG_ID: 'o-1EQRA5-na1',

  REACT_APP_CROSSBEAM_STATUS_URL: 'https://status.crossbeam.com',
  ENVIRONMENT: 'production',
};

export default config;
