// @flow

import * as React from 'react';
import appConfig from 'appConfig';
import queryString from 'qs';

import { OrgUser } from 'data/entities';
import withOrguser from 'contexts/withOrguser';
import { useSlackV2oAuth } from 'utils/environment';
import { slackRedirectDomain } from 'utils/urlUtils';

const prodClientId = '102068978614.443020162801';
const clientId = appConfig.REACT_APP_SLACK_CLIENT_ID || prodClientId;

const SlackIntegrationButton = ({ loggedInOrguser }: { loggedInOrguser: OrgUser }) => {
  const redirectUri = `${slackRedirectDomain()}/oauth/slack-integration`;

  // On prod we don't want v2 scopes
  let baseUrl;

  const params = {
    client_id: clientId,
    redirect_uri: redirectUri,
    state: loggedInOrguser.stateCode,
  };

  if (useSlackV2oAuth) {
    baseUrl = 'https://slack.com/oauth/v2/authorize';
    params.scope =
      'channels:join,channels:read,chat:write,commands,channels:write.invites,groups:write.invites,groups:read,im:history,im:read,im:write,links:read,links:write,mpim:read,team:read,users:read,users:read.email,channels:history,groups:history';
  } else {
    baseUrl = 'https://slack.com/oauth/authorize';
    params.scope = 'commands,chat:write:bot,team:read,bot,users:read,users:read.email';
  }
  const paramStr = queryString.stringify(params);
  const slackUrl = `${baseUrl}?${paramStr}`;
  return (
    <a href={slackUrl} target="_blank">
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};

export default withOrguser(SlackIntegrationButton);
