// @flow

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import appConfig from 'appConfig';

import authFetch from 'authFetch/authFetch';
import { ErrorResponse } from 'authFetch/entities';
import { setAuthToken } from 'authFetch/utils';

import ForgotPasswordSignupButton from 'views/Auth/ForgotPasswordSignupButtons';

const RECAPTCHA_SITE_KEY = appConfig.REACT_APP_RECAPTCHA_SITE_KEY;

type Props = {
  doLogin?: (doOnboarding: boolean) => Promise<any>,
  acceptedInvite?: string,
};

export default class LoginForm extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      captcha: null,
      acceptedInvite: this.props.acceptedInvite,
      ErrorMessage: '',
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.recaptchaRef.current.execute();
    const { email } = this.props;
    if (email) {
      this.setState({ email });
    }
  }

  onSubmitBasicAuth = (e) => {
    e.preventDefault();
    const body = {
      email: this.state.email,
      password: this.state.password,
      accepted_invite_code: this.state.acceptedInvite,
      captcha: this.state.captcha,
    };
    this.setState({
      ErrorMessage: '',
    });
    authFetch('auth/basic/', { method: 'POST', body })
      .then(({ token }) => {
        setAuthToken(token);
        this.props.doLogin();
        return { token };
      })
      .catch((errors: ErrorResponse) => {
        const { json } = errors;
        let ErrorMessage = '';
        if (!json) {
          ErrorMessage = 'Something went wrong.';
        } else if (json.nonFieldErrors) {
          ErrorMessage = json.nonFieldErrors;
        } else if (json.captcha) {
          ErrorMessage = json.captcha;
        } else {
          ErrorMessage = json;
        }
        this.setState({
          ErrorMessage,
          captcha: null,
        });
        if (this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
          this.recaptchaRef.current.execute();
        }
      });
  };

  onInputchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onCaptchaChange = (CaptchaValue) => {
    if (CaptchaValue === null) {
      this.recaptchaRef.current.execute();
      return;
    }
    this.setState({
      captcha: CaptchaValue,
    });
  };

  render() {
    const { acceptedInvite } = this.state;
    const { captcha } = this.state;
    const disableSubmitButton = captcha === null;
    const tooltipId = 'hidden-tooltip';
    return (
      <div className="justify-content-center d-flex">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h4 className="mt-1 mb-4 text-center">Log in to Crossbeam for Sales</h4>
              {this.state.ErrorMessage && Array.isArray(this.state.ErrorMessage) ? (
                <div className="alert alert-danger" role="alert">
                  {this.state.ErrorMessage[0]}
                </div>
              ) : (
                ''
              )}
              <form onSubmit={this.onSubmitBasicAuth}>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${this.state.ErrorMessage.email ? 'is-invalid' : ''}`}
                    id="email"
                    aria-describedby="emailHelp"
                    onChange={this.onInputchange}
                    value={this.state.email}
                    placeholder="Enter your email"
                  />
                  {this.state.ErrorMessage.email ? (
                    <div className="invalid-feedback">{this.state.ErrorMessage.email[0]}</div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onInputchange}
                    className={`form-control ${
                      this.state.ErrorMessage.password ? 'is-invalid' : ''
                    }`}
                    id="password"
                    aria-describedby="emailHelp"
                    placeholder="Enter your password"
                  />
                  {this.state.ErrorMessage.password ? (
                    <div className="invalid-feedback">{this.state.ErrorMessage.password[0]}</div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      ref={this.recaptchaRef}
                      size="invisible"
                      onChange={this.onCaptchaChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  {disableSubmitButton && (
                    <UncontrolledTooltip placement="top" target={tooltipId}>
                      Loading captcha...
                    </UncontrolledTooltip>
                  )}
                  <span id={tooltipId}>
                    <button
                      disabled={disableSubmitButton}
                      style={{ pointerEvents: disableSubmitButton ? 'none' : '' }}
                      type="submit"
                      className="btn btn-primary btn-lg text-center font-weight-bold btn-block mb-3"
                    >
                      Login
                    </button>
                  </span>
                  <ForgotPasswordSignupButton acceptedInviteCode={acceptedInvite} />
                </div>
              </form>
            </div>
          </div>
          <div className="row text-center mt-3">
            <div className="col-md-12">
              <Link to={acceptedInvite ? `/invite/${acceptedInvite}` : '/login'}>
                <b>Use single sign-on (SSO) instead</b>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
