// @flow
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appConfig from 'appConfig';

import { fetchCrossbeamIntegration } from 'data/repositories/crossbeam';
import useParams from 'utils/useParams';
import { generateAuthStateParam } from 'authFetch/utils';

import BareLayout from 'layouts/BareLayout';
import LoadingRing from 'components/LoadingRing';
import LogoCrossbeam from 'components/LogoCrossbeam';

import withOrguser from '../../contexts/withOrguser';

type Props = {
  onboarding: () => void,
  hubspotFlow?: boolean,
  loggedInOrguser: Orguser,
};

const ConnectCrossbeam = ({ onboarding, hubspotFlow, loggedInOrguser }: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [params] = useParams({});
  const [authStateParam, setAuthStateParam] = useState(null);

  useEffect(() => {
    setAuthStateParam(generateAuthStateParam(loggedInOrguser.id, hubspotFlow));
  }, [hubspotFlow, loggedInOrguser]);

  const authRedir = `${appConfig.REACT_APP_CROSSBEAM_DATA_AUTH_URL}&state=${authStateParam}`;

  useEffect(() => {
    setLoading(true);

    fetchCrossbeamIntegration()
      .then((ci) => {
        if (ci && ci.status === 'ACTIVE') {
          onboarding();
          setLoading(false);
        } else if (params.skip === 'true') {
          window.location.href = authRedir;
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onboarding, params.skip, authRedir]);

  const onConnectCrossbeam = () => {
    // Open a popup with the auth flow. When that flow is done, take all the query params and
    // load `oauth/crossbeam-data/received` in the main window the oauth flow on our side

    // Used to make sure the popup opens in the middle of the window
    const h = 790;
    const w = 700;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;

    const windowFeats = `popup=true, width=${w}, height=${h}, top=${y}, left=${x}, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, copyhistory=no,`;
    const popup = window.open(authRedir, 'popup', windowFeats);
    const checkPopup = setInterval(() => {
      let loc = '';
      try {
        loc = popup.window.location.href;
      } catch {
        loc = '';
      }
      if (loc.includes('/crossbeam-data')) {
        history.push(`/oauth/crossbeam-data/received${popup.window.location.search}`);
        popup.close();
        clearInterval(checkPopup);
      }
      if (!popup || !popup.closed) return;
      clearInterval(checkPopup);
    }, 1000);
  };

  return (
    <BareLayout centered>
      <h3 className="text-black bold">Enable and Configure Crossbeam for Sales</h3>
      <span className="large-text bold gray-500 mb-5 text-center">
        Enable Crossbeam for Sales and configure <br />
        the partners your team can work with.
      </span>
      <div className="d-flex flex-column align-items-center gap-20" style={{ width: '500px' }}>
        {loading ? (
          <LoadingRing maxWidth="50px" />
        ) : (
          <button
            type="button"
            onClick={onConnectCrossbeam}
            className="btn btn-primary text-white bg-crossbeam-blue px-5"
          >
            <LogoCrossbeam text={false} className="mr-2" height={18} width={18} />
            Enable Crossbeam for Sales
          </button>
        )}
      </div>
    </BareLayout>
  );
};

ConnectCrossbeam.defaultProps = {
  hubspotFlow: false,
};

export default withOrguser(ConnectCrossbeam);
