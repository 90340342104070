// @flow

import React from 'react';
import appConfig from 'appConfig';
import { isEmpty } from 'lodash';

import { isInIframe } from '../../utils/useInIframe';

type Props = {
  defaultEmails?: string[],
};

const baseUrl = appConfig.REACT_APP_CROSSBEAM_URL || 'https://app.crossbeam.com';

const InviteUsers = ({ defaultEmails, children }: Props) => {
  const emailsQueryParam = !isEmpty(defaultEmails) ? `?emails=${defaultEmails.join(',')}` : '';

  const url = `${baseUrl}/team/invite${emailsQueryParam}`;
  return (
    <a href={url} target={isInIframe() ? '_parent' : '_blank'}>
      {children}
    </a>
  );
};

InviteUsers.defaultProps = {
  defaultEmails: [],
};

export default InviteUsers;
